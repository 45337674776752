<template>
  <nav
    class="h-nav-mobile fixed z-top w-full bg-white transition-shadow"
    aria-label="main"
    :class="lastYPostition < 10 ? 'shadow-none' : 'shadow-md'"
  >
    <Container :class="{ 'border-b border-gray-800/20': open }">
      <div class="flex justify-between py-6">
        <!-- LOGO -->
        <div
          class="flex w-44 max-w-[42.5vw] transition-opacity"
          :class="{ 'opacity-0 ': open }"
        >
          <NuxtLink
            to="/"
            class="focus-default -m-2 w-full p-2"
            aria-label="Link zur Startseite"
          >
            <IconLogo class="w-full object-contain" />
          </NuxtLink>
        </div>

        <!-- Burger Icon -->
        <div class="flex min-w-max" @click="open = !open">
          <button
            :aria-expanded="open"
            class="focus-default -mr-2 pl-6 pr-2"
            :class="{ 'font-medium text-gray-800': open }"
            :aria-label="open ? 'Navigation schliessen' : 'Navigation öffnen'"
          >
            Menu<span v-if="open">&nbsp;schliessen</span>
            <IconBurger
              class="ml-2 inline-block w-5 text-gray-800"
              :open="open"
            />
          </button>
        </div>
      </div>
    </Container>
    <!-- NAVIGATION POPUP -->
    <div v-if="open" class="scrollbar-none max-h-screen overflow-y-scroll">
      <NavigationMobilePopup class="pb-[25vh]" :nav="nav" :meta="meta" />
    </div>
  </nav>
</template>

<script setup>
defineProps({
  nav: { type: Object, required: true },
  meta: { type: Object, required: true },
})
const open = ref(null)
const route = useRoute()
const dropdown = ref({})
const focusindex = ref(1)
open.value = false

let lastYPostition = ref(0)
useEventListener('scroll', () => {
  const { y } = useWindowScroll()
  lastYPostition.value = y.value
})

watch(
  () => route.fullPath,
  () => {
    open.value = false
  }
)
</script>
